import React, { useEffect, useState, useRef } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Spinner from "./Spinner";
import ErrorHandler from "./ErrorHandler";

const APIKEY =  "AIzaSyChlclitoyVCuFTJ-HvhWC9xe8bSd58DuA";

export const MapWrapper = (props) => {

	function render(status) {
		if (status === Status.LOADING) return <Spinner />;
		if (status === Status.FAILURE) return <ErrorHandler/>;
		return null;
	};

	let infoWindow = null;

	function MyMarker({position, map}) {
		const [marker, setMarker] = useState(null);
		const CONTENT = "<H4>You Are Here</H4><p>Position by <br/>Location Service</p>"+
		"<p>LAT: "+position.lat+" <br/>LNG: "+position.lng+"</p>";

		useEffect(() => {
			setMarker(new window.google.maps.Marker());
		}, []);

		if (marker) {
			marker.setMap(map);
			marker.setPosition(position);
			marker.setTitle("You Are Here");
			if (!infoWindow){
				infoWindow = new window.google.maps.InfoWindow({
					content: CONTENT,
					ariaLabel: "Location"
				});

			}
			marker.addListener("click", () => {
				infoWindow.open({
					anchor: marker,
					map
				})
			});
		}
		return null;
	}
 
	function MyMapComponent({position, zoom=13, children}) {
		const ref = useRef();
		const [map, setMap] = useState(null);
		const style = { map : {width:'100%', height:'60vh'}}
		
		useEffect(() => {
			setMap(new window.google.maps.Map(ref.current, {}));
		}, [setMap]);

		if(map) {
			map.setCenter(position);
			map.setZoom(zoom);
		}
		
		return <div ref={ref} style={style.map} id="map">
			{React.Children.map(children, 
				(child) => React.cloneElement(child, {map})
			)}
		</div>;
	}
 
	let position = {lat:-89.9, lng:0};
	let zoom = props.zoom;

  	if ((props.lat != null) && (props.lng != null)) {
    	position = {lat:props.lat, lng:props.lng};
    	//console.log('POS',position);
  	}

  	return (
		<Wrapper apiKey={APIKEY} render={render}>
			<MyMapComponent position={position} zoom={zoom}>
				<MyMarker position={position} />
			</MyMapComponent>
		</Wrapper>
  	);

};


